import { useQuery } from "@apollo/client"
import { graphql, useStaticQuery } from "gatsby"
import gql from "graphql-tag"
import { Dashboard, DocumentUser, Golang, Upload } from "grommet-icons"
import {
  Button,
  Checkmark,
  Comment,
  Controller,
  Draft,
  DrawingTask,
  Lessons,
  Megaphone,
  MyHistory,
  MyZoo,
  NzkPurple,
  NzktvGreen,
  Private,
  Projects,
  Task,
  Writing,
} from "nzk-react-components"
import React, { useState } from "react"
import Helmet from "react-helmet"
import styled, { css } from "styled-components"
import Expanded from "./Expanded"
import Small from "./Small"

const Wrapper = styled.div<{ expanded: boolean }>`
  width: ${props => (props.expanded ? `170px` : `70px`)};
  height: 100%;
  ${props => props.expanded && css`
    transition: width 0.2s linear;
  `}
  > * {
    position: fixed;
    top: 0;
    left: 0;
  }
  > :first-child {
    visibility: ${props => (props.expanded ? `hidden` : `visible`)};
    z-index: 10;
  }

  > :nth-child(2) {
    z-index: 9;
    visibility: ${props => (props.expanded ? `visible` : `hidden`)};
    transform: translateX(${props => (props.expanded ? "0px" : "-100px")});
    transition: transform 0.2s linear;
  }
`

interface IProps {
  title?: string
  location: string
}

const SideNav = (props: IProps) => {
  const { location, title } = props
  const [expanded, setExpanded] = useState(false)

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const { data } = useQuery(
    gql`
      {
        me {
          _id
          name
          email
          avatar {
            url
          }
        }
      }
    `
  )

  const sections = [
    // {
    //   items: [
    //     {
    //       match: /dashboard/,
    //       path: "/dashboard",
    //       icon: <Dashboard height="18px" width="18px" />,
    //       name: "Dashboard",
    //     },
    //   ],
    // },
    // [
    //   {
    //     match: /customers/,
    //     path: "/customers",
    //     icon: <UserSettings />,
    //     name: "Customers",
    //   },
    //   {
    //     match: /student/,
    //     path: "/students",
    //     icon: <Group />,
    //     name: "Students",
    //   },
    // ],
    {
      title: 'Tutoring',
      items: [
        {
          match: /writings/,
          path: "/writings",
          icon: <Writing height="18px" width="18px" />,
          name: "Writing",
        },
        {
          match: /comments/,
          path: "/comments",
          icon: <Comment height="18px" width="18px" />,
          name: "Comments",
        },
        {
          match: /drawings/,
          path: "/drawings",
          icon: <DrawingTask height="18px" width="18px" />,
          name: "Drawings",
        },
        {
          match: /animals-feed$/,
          path: "/animals-feed",
          icon: <MyZoo height="18px" width="18px" />,
          name: "Pending animals",
        },
        {
          match: /avatars-feed$/,
          path: "/avatars-feed",
          icon: <DocumentUser />,
          name: "Avatars Feed",
        },
        // {
        //   match: /animals$/,
        //   path: "/animals",
        //   icon: <Golang />,
        //   name: "Featured animals",
        // },
        {
          match: /book-records/,
          path: "/book-records",
          icon: <MyHistory height="18px" width="18px" />,
          name: "Book records",
        },
        {
          match: /projects/,
          path: "/projects",
          icon: <Projects height="18px" width="18px" />,
          name: "Projects",
        },
      ],
    },
    {
      title: 'Advanced Tutoring',
      items: [
        {
          match: /star-tutoring\/lineups/,
          path: "/star-tutoring/lineups",
          icon: <MyHistory height="18px" width="18px" />,
          name: "Lineups",
        },
        {
          match: /star-tutoring\/assignment-templates/,
          path: "/star-tutoring/assignment-templates",
          icon: <Draft height="18px" width="18px" />,
          name: "Templates",
        },
        {
          match: /star-tutoring\/zookeepers/,
          path: "/star-tutoring/zookeepers",
          icon: <Private height="18px" width="18px" />,
          name: "Zookeepers",
        },
        {
          match: /star-tutoring\/reviews/,
          path: "/star-tutoring/reviews",
          icon: <Button height={18} round theme='confirm'>
            <Checkmark fill='green' height="18px" width="18px" />
          </Button>,
          name: "Reviews",
        },
      ],
    },
    {
      title: 'Shop',
      items: [
        {
          match: /orders/,
          path: "/orders",
          icon: <Task height="18px" width="18px" />,
          name: "Orders",
        },
      ],
    },
    {
      title: 'Management',
      items: [
        {
          match: /tutors/,
          path: "/tutors",
          icon: <NzkPurple height="18px" width="18px" />,
          name: "Tutors",
        },
        {
          match: /events/,
          path: "/events",
          icon: <Controller height="18px" width="18px" />,
          name: "Events",
        },
        {
          match: /send-lesson/,
          path: "/send-lesson",
          icon: <NzktvGreen height="18px" width="18px" />,
          name: "Send Lesson",
        },
        {
          match: /send-message/,
          path: "/send-message",
          icon: <Megaphone height="18px" width="18px" />,
          name: "Send Message",
        },
        {
          match: /(^\/lesson)|(^\/create-lesson)/g,
          path: "/lessons",
          icon: <Lessons height="18px" width="18px" />,
          name: "Lessons",
        },
      ],
    },
    {
      title: 'Tools',
      items: [
        { match: /upload/, path: "/upload", icon: <Upload />, name: "Upload" },
      ],
    },
  ]

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en-gb",
        }}
        title={title}
        titleTemplate={`%s | ${site.siteMetadata.title}`}
      />
      <Wrapper expanded={expanded}>
        <Small
          location={location}
          sections={sections}
          data={data}
          expand={() => setExpanded(true)}
        />
        <Expanded
          location={location}
          sections={sections}
          data={data}
          unexpand={() => setExpanded(false)}
        />
      </Wrapper>
    </>
  )
}

SideNav.defaultProps = {
  title: "Dashboard",
}

export default SideNav
